.custom-calendar {
    background-color: white;
    border: none;
    border-radius: 5px;
}

.custom-calendar .MuiPickersDay-root {
    border-radius: 50%;
    width: 40px; /* Adjust the width as needed */
    height: 40px; /* Ensure the height is the same as the width */
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-calendar .MuiPickersDay-root.Mui-selected {
    background-color: var(--clr-mindly-purple-hover);
    color: white;
}

.custom-calendar .MuiPickersDay-root.MuiPickersDay-today {
    background-color: var(--clr-mindly-background);
    color: var(--clr-mindly-black);
}