@import url('https://fonts.googleapis.com/css2?family=Kablammo&family=Karla:wght@300;400&family=Lato:ital,wght@0,300;0,400;1,300;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
  font-family: 'Rnd Gthc Bk';
  src: url('./Fonts/Fontspring-DEMO-allroundgothic-book.otf');
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-theme-gray);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--clr-theme-gray);
  border-radius: 40px;
}

#root {
  height: 100vh;
  width: 100vw;
}

/* Color themes for the application */
:root {
  --clr-theme-white: #f6f7fe;
  --clr-theme-purple: #9291f0;
  --clr-theme-gray-dark: rgba(0, 0, 0, 0.75);
  --clr-theme-gray: rgba(0, 0, 0, 0.63);

  --clr-error: red;
  --clr-success: green;

  --clr-theme-purple-50: #bbbbf2e6;
  --clr-theme-purple-20: #9291f033;

  --clr-text-dark: #25478a;
  --clr-white-20: rgba(255, 255, 255, 0.2);
  --clr-white-80: rgba(255, 255, 255, 0.8);

  --clr-google-meet: hsl(120, 100%, 90%);
  --clr-icon-lightgray: rgb(166, 162, 162);
  --clr-theme-grayWhite: rgba(243, 243, 243, 1);
  --clr-theme-lightG: rgba(231, 231, 231, 50%);
  --clr-theme-lgray: rgb(217, 217, 217);

  --clr-mindly-background: #e9eaff;
  --clr-mindly-purple: #9192ef;
  --clr-mindly-purple-hover: #6d6ede;
  --clr-mindly-white: #ffffff;
  --clr-mindly-black: #000000;
  --clr-mindly-light-gray: #f6f7fc;
  --clr-mindly-gray: #e1e1e1;
}

/* Generic Classes to be used throughout the application. */
.flexBox {
  display: flex;
}

.flexCenter {
  justify-content: center;
  align-items: center;
}

.flexRow {
  flex-direction: row;
}

.flexColumn {
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}

.errorMessage {
  color: var(--clr-error);
}
.successMessage {
  color: var(--clr-success);
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.clipText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.btn {
  color: #fff;
  background-color: var(--clr-theme-purple) !important;
  border: 1px solid var(--clr-theme-purple-50) !important;
}
input:-webkit-autofill,
.add-goal-details input:-webkit-autofill:hover,
.add-goal-details input:-webkit-autofill:focus,
.add-goal-details input:-webkit-autofill:active {
  background-color: var(--clr-mindly-white) !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--clr-mindly-white) inset !important;
  box-shadow: 0 0 0px 1000px var(--clr-mindly-white) inset !important;
}
