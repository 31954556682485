.allGoals .MuiTypography-root {
    font-family: 'Lato';
}

.MainLayout .MuiCardContent-root {
    overflow-y: hidden;
}

.allGoals .recharts-surface {
    height: 101% !important;
}