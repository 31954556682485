.data-entry-model {
    display: flex;
    flex-direction: column;
}

.data-entry-model .MuiTypography-root {
    font-family: 'Lato';
}

.data-entry-model .MuiFormControl-root {
    margin: 0px;
}

.data-entry-model .MuiInputBase-root{
    background-color: white;
  }

.data-entry-model .input-height .MuiInputBase-input.MuiOutlinedInput-input{
    height: 0.5rem;
}