.messaging .selected {
  background: var(--clr-theme-white);
  border-radius: 7px;
  color: var(--clr-theme-purple);
}
.message-container {
  background: var(--clr-theme-white);
  border-radius: 7px;
  color: var(--clr-theme-purple);
}

.message-container .chatDate {
  display: flex;
  justify-content: center;
  width: 100%;
  line-height: 5px;
  margin-top: 5px;
  /* border:2px solid red; */
}

.message-right-panel .MuiCardHeader-content span.MuiTypography-root.MuiTypography-h5.MuiCardHeader-title{
  font-size: 20px;
}