.add-goal-details {
  background-color: #e9eaff;

  /* padding-left: 3rem; */
  /* padding-top: 3rem; */
  border-radius: 5px;
  max-width: 100%;
  margin: 0 auto;
}
.grid-item::-webkit-scrollbar {
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.heading {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
  margin-bottom: 40px;
}

.button {
  display: flex;
  width: 90% !important;
  min-width: 200px;
  height: 48px;
  align-items: center;
  background-color: var(--clr-theme-purple) !important;
  border-color: var(--clr-theme-purple) !important;
  color: white !important;
  font-family: 'Lato' !important;
  text-transform: capitalize !important;
  align-items: center;
  justify-content: center;
}

.grid-item {
  grid-column: span 12;
}

.grid-item-md {
  grid-column: span 8;
}

.select-student-box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-radius: '20px' !important;
  justify-content: left;
}

.select-student-typography {
  font-family: 'Lato' !important;
  font-size: 18px;
  white-space: nowrap;
}

.select-student-form-control {
  min-width: 200px;
  border-radius: 20;
}

.student-info-box {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 16px;
  width: 60%;
}

.add-goal-details .MuiAvatar-root {
  height: 150px;
  width: 150px;
  margin-right: 8px;
}

.student-textfield {
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 8px;
  background-color: var(--clr-theme-purple) !important;
  border-radius: 5px !important;
  color: white;
}

.goal-box {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 16px;
}

.goal-box-flex {
  flex: 1;
  gap: 2;
}

.goal-box-typography {
  font-family: 'Lato' !important;
  font-size: 18px;
  white-space: nowrap;
  margin-bottom: 8px;
}

.goal-box-typography.radio-group-clr {
  border: white;
}

.goal-textfield {
  margin-bottom: 16px;
  background-color: white;
  /* border-radius: 15px; */

  color: 'transparent';
}

.switch-color .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: grey !important;
  /* Background when ON */
  padding: 4;
}

.switch-color .MuiSwitch-track {
  background-color: white !important;
  /* Background when OFF */
  /* border-radius: 10px !important; */
}

.switch-color .MuiSwitch-thumb {
  color: var(--clr-theme-purple) !important;
  /* Thumb color when OFF */
  width: 12px !important;
  /* Smaller thumb width */
  height: 12px !important;
  /* Smaller thumb height */
}

.switch-color .MuiSwitch-switchBase {
  padding: 12px !important;
  /* Reduces padding to fit smaller thumb */
}

.radio-group-clr .MuiRadio-root {
  color: var(--clr-theme-purple) !important;
  /* Makes the radio circle white */
  display: flex;
}

.radio-group-clr .Mui-checked {
  color: var(--clr-theme-purple) !important;
  /* Makes the radio circle white when selected */
}

.radio-group-clr .MuiTouchRipple-root {
  display: white !important;
}

.check-box-clr .MuiCheckbox-root {
  color: white !important;
  /* Unchecked checkbox background */
  border: 2px solid blue !important;
  /* Blue border */
  border-radius: 4px;
  /* Slight rounded corners */
  background-color: white !important;
}

/* Optional: Removes the default ripple effect */
.check-box-clr .MuiTouchRipple-root {
  display: none;
}

.radio-clr {
  background-color: white;
  padding: 0.01px;
}

.student-info-box.MuiInputLabel-root {
  color: 'white';
}

.student-info-box.MuiInputLabel-root.Mui-focused {
  color: 'white';
}

.student-info-box.MuiOutlinedInput-input {
  color: 'white';
}

/* .radio-group-clr.form-control-clr {
gap:"5px"
} */
.form-control-clr {
  gap: "5px"
}

fieldset {
  border: 1px solid #9192ef;
  /* box-shadow:  0px 0px 10px rgba(0,0,0,0.1); */
}

.add-goal-details .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth {
  border: 1px solid transparent;
}

.add-goal-details div.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.goal-textfield {
  background-color: var(--clr-mindly-white);
  border-radius: 8px;
}

.add-goal-details div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  height: 40px;
}

.add-goal-details .react-date-picker .react-date-picker__wrapper {
  border: none !important;
}

.add-goal-details input:-webkit-autofill,
.add-goal-details input:-webkit-autofill:hover,
.add-goal-details input:-webkit-autofill:focus,
.add-goal-details input:-webkit-autofill:active {
  background-color: var(--clr-mindly-white) !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--clr-mindly-white) inset !important;
  box-shadow: 0 0 0px 1000px var(--clr-mindly-white) inset !important;
  height: 7px;
}

/* Specific Fix for MUI Outlined Inputs */
.add-goal-details .MuiInputBase-root input:-webkit-autofill {
  background-color: var(--clr-mindly-white) !important;
}
