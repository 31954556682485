.Reminders .MuiTypography-root {
  font-family: "Lato";
}
.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: var(--clr-theme-purple-20);
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
  border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
  border-bottom: 1px solid bisque;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l11 2s infinite;
}
@keyframes l11 {
  0% {
    background: conic-gradient(#9291f0 0, #0000 0);
  }
  12.5% {
    background: conic-gradient(#9291f0 45deg, #0000 46deg);
  }
  25% {
    background: conic-gradient(#9291f0 90deg, #0000 91deg);
  }
  37.5% {
    background: conic-gradient(#9291f0 135deg, #0000 136deg);
  }
  50% {
    background: conic-gradient(#9291f0 180deg, #0000 181deg);
  }
  62.5% {
    background: conic-gradient(#9291f0 225deg, #0000 226deg);
  }
  75% {
    background: conic-gradient(#9291f0 270deg, #0000 271deg);
  }
  87.5% {
    background: conic-gradient(#9291f0 315deg, #0000 316deg);
  }
  100% {
    background: conic-gradient(#9291f0 360deg, #0000 360deg);
  }
}
/* .highlighted-date {
  background-color: #9291f0;
 
  color: aliceblue;
} */

.highlight {
  background: #9291f0 !important; 
  color: black !important;
}

.react-calendar__tile {

  background: transparent ;
  color: inherit ;
}

.mindly-reminders .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd{
  height: 40px;
}

.mindly-reminders .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthLg.css-1fu2e3p-MuiPaper-root-MuiDialog-paper{
  border-radius: 20px;
  border: 1px solid #6e7575;
}


.MuiPickerStaticWrapper-content.css-xelq0e-MuiPickerStaticWrapper-content,
.MuiPickerStaticWrapper-root.custom-calendar.css-u0soqy-MuiPickerStaticWrapper-root {
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.mindly-reminders span.css-hyxlzm{
  color: var(--clr-mindly-purple);
}