.viewGoalsOverviewContainer {
  display: grid;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding-right: 10px;
  /* padding-left: 10px; */
  grid-template-columns: 35% 34% 31%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "teacher-goals-area teacher-goals-area teacher-patient-profile-area"
    "teacher-in-perspective-area teacher-progress-area teacher-patient-profile-area";
}

/* Hide the scrollbar */
.viewGoalsOverviewContainer::-webkit-scrollbar {
  display: none;
}

.viewGoalsOverviewContainer .teacher-goals-area {
  grid-area: teacher-goals-area;
}
.viewGoalsOverviewContainer .teacher-in-perspective-area {
  grid-area: teacher-in-perspective-area;
}
.viewGoalsOverviewContainer .teacher-progress-area {
  grid-area: teacher-progress-area;
}

.viewGoalsOverviewContainer .teacher-patient-profile-area {
  grid-area: teacher-patient-profile-area;
  height: 100%;
}

/* Card area styling */
.viewGoalsOverviewContainer .viewGoalsOverviewCard {
  height: 100%;
  border-radius: 10px;
  overflow-y: visible;
  background-color: var(--clr-theme-white);
  box-shadow: 1px 1px 10px var(--clr-theme-purple-50);
}

.viewGoalsOverviewContainer .viewGoalsPatientCard {
  height: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 10px var(--clr-theme-purple-50);
  background-color: #9291f033;
}

.viewGoalsOverviewContainer .viewGoalsOverviewCard .MuiCardContent-root {
  height: 37.5vh;
}

.viewGoalsOverviewContainer .viewGoalsPatientCard .MuiCardContent-root {
  height: 100%;
}

/* Button Styling */
.viewAllButton {
  background-color: var(--clr-theme-white) !important;
  color: var(--clr-theme-gray) !important;
  text-transform: capitalize !important;
  border: 1px solid gray;
  font-family: "Lato" !important;
  font-weight: 400;
  width: 8.625rem !important;
}

/* Responsive Design (Tablet/Phone) */

/* Tablets and below (max-width: 768px) */
@media (max-width: 768px) {
  .viewGoalsOverviewContainer {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      "teacher-goals-area teacher-patient-profile-area"
      "teacher-in-perspective-area teacher-progress-area";

    padding: 10px;
  }
  .viewGoalsOverviewContainer .teacher-patient-profile-area {
    grid-area: teacher-patient-profile-area;
    height: 40vh;
  }

  .viewGoalsOverviewContainer .viewGoalsOverviewCard,
  .viewGoalsOverviewContainer .viewGoalsPatientCard {
    box-shadow: 1px 1px 5px var(--clr-theme-purple-50); /* Reduced box-shadow for mobile */
  }

  .viewGoalsOverviewContainer .viewGoalsOverviewCard .MuiCardContent-root {
    height: 30vh; /* Adjusted for smaller screens */
  }

  .viewGoalsOverviewContainer .viewGoalsPatientCard .MuiCardContent-root {
    height: auto; /* Remove fixed height */
  }

  .viewAllButton {
    width: 100%; /* Make button full-width on smaller screens */
  }

  .teacher-patient-profile-area {
    grid-column: 1 / span 1; /* Ensure patient profile occupies full width on mobile */
    grid-row: auto;
  }

  /* Adjusting text and card sizes */
  .viewGoalsOverviewContainer .viewGoalsPatientCard .MuiCardContent-root {
    font-size: 14px; /* Reduce font size for mobile */
  }

  .viewGoalsOverviewContainer .viewGoalsOverviewCard .MuiCardContent-root {
    font-size: 14px; /* Reduce font size for mobile */
  }
}

/* Small mobile (max-width: 480px) */
@media (max-width: 480px) {
  .viewGoalsOverviewContainer {
    grid-template-columns: 100%;
    grid-template-areas:
      "teacher-goals-area"
      "teacher-in-perspective-area"
      "teacher-progress-area"
      "teacher-patient-profile-area";
    padding: 10px;
  }

  .viewGoalsOverviewContainer .viewGoalsOverviewCard,
  .viewGoalsOverviewContainer .viewGoalsPatientCard {
    box-shadow: 1px 1px 3px var(--clr-theme-purple-50); /* Lighter box-shadow */
  }

  .viewGoalsOverviewContainer .viewGoalsOverviewCard .MuiCardContent-root {
    height: 25vh; /* Adjusted content height for smaller screens */
  }

  .viewGoalsOverviewContainer .viewGoalsPatientCard .MuiCardContent-root {
    height: auto;
  }

  .viewAllButton {
    width: 100%; /* Full width for small mobile */
    font-size: 12px; /* Smaller font size for mobile */
  }

  .teacher-patient-profile-area {
    /* grid-column: 1 / span 1; Full-width profile on small screens */
    /* grid-row: auto; */
  }

  .viewGoalsOverviewContainer .viewGoalsPatientCard .MuiCardContent-root {
    font-size: 12px;
  }

  .viewGoalsOverviewContainer .viewGoalsOverviewCard .MuiCardContent-root {
    font-size: 12px;
  }

  /* Adjust text size inside cards */
  .viewGoalsOverviewContainer .viewGoalsOverviewCard .MuiCardContent-root p {
    font-size: 14px;
  }

  /* Reduce image size */
  .teacher-patient-profile-area img {
    width: 12vh; /* Smaller image size for mobile */
    height: 12vh;
  }
}

/* Medium screens (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .viewGoalsOverviewContainer {
    grid-template-columns: 35% 35% 30%;
    grid-template-areas:
      "teacher-goals-area teacher-goals-area teacher-patient-profile-area"
      "teacher-in-perspective-area teacher-progress-area";
    padding: 10px;
  }

  .viewGoalsOverviewContainer .viewGoalsOverviewCard {
    height: 40vh;
  }

  .viewGoalsOverviewContainer .viewGoalsPatientCard .MuiCardContent-root {
    height: 100%;
  }

  /* .teacher-patient-profile-area {
    grid-column: 2;
    grid-row: span 2;
  } */

  .viewAllButton {
    width: 100%;
  }
}

.container {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  /* align-content: start; */
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
  padding: 10px;
}

.item:nth-child(1) {
  /* width: 100%; */
  height: 5%;
  border: 1px solid black;
}

.item:nth-child(2) {
  width: 65%;
  height: 100%;
  border: 1px solid black;
}

.item:nth-child(3) {
  flex-grow: 1;
  height: 100%;
  border: 1px solid black;
}

.goalDashboard {
  height: calc(100vh - 100px);
  /* border:1px solid black; */
  /* display: flex; */
  /* flex-direction: row; */
  /* gap: 20px; */
  /* padding: 10px; */
  /* flex-wrap: wrap; */
  /* align-items: flex-start; */
  /* justify-content: center; */
  min-width: 320px;
}

.goalDashboard .box-area1 {
  height: 100%;
  min-width: 320px;
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.goalDashboard .box-area2 {
  height: 100%;
  min-width: 320px;
  flex: 1 1 38%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border: 1px solid; */
}

.goalDashboard .box-area1 .tableData {
  height: 48%;
  border: 1.5px solid darkGray;
  border-radius: 12px;
  min-height: 350px;
}

.goalDashboard .box-area1 .stats {
  height: 100%;
}

.goalDashboard .box-area2 .summary {
  height: 100%;
  padding: 10px 30px;
  background-color: var(--clr-mindly-background);
  border-radius: 15px;
  min-height: 350px;
}

.goalDashboard .box-area2 .summary .viewGoalsPatientCard {
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
}

.goalDashboard .box-area2 .data {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  scrollbar-width: none;
  min-height: 350px;

  /* border: 1px solid black; */
}

.card {
  /* flex: 1 1 calc(33.333% - 16px); */
  max-width: 345px;
  /* margin: 8px; */
}

@media (max-width: 1200px) {
  .card {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 600px) {
  .card {
    flex: 1 1 100%;
  }
}

@media (max-width: 800px) {
  .goalDashboard {
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 10px;
  }

  .goalDashboard .box-area1,
  .goalDashboard .box-area2 {
    width: 100%;
  }
}

.upperHeader .MuiButtonBase-root {
  background-color: var(--clr-mindly-purple);
  text-transform: capitalize;
  font-family: "Lato";
}
.goalDashboard .MuiButtonBase-root {
  background-color: var(--clr-mindly-purple);
  text-transform: capitalize;
  font-family: "Lato";
}
.goalDashboard .MuiButtonBase-root:hover {
  background-color: var(--clr-mindly-purple-hover);
}

.upperHeader .MuiButtonBase-root:hover {
  background-color: var(--clr-mindly-purple-hover);
}

 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  box-shadow: 0px 1px 1px 0px var(--clr-mindly-background);
  border: 1px solid var(--clr-mindly-background);
}

.mindly-table-cell{
    font-weight: bold !important;
    /* text-align: center !important; */
    width: 20% !important;
    padding: 8px 12px !important;
    background-color: var(--clr-mindly-background) !important;
}